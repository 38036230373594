<template>
  <div class="vg_wrapper">
    <el-card v-loading="isLoading">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm">
        <el-row>
          <el-col :md="6">
            <el-form-item label="所属模块：" >
              <el-select
                size="small"
                v-model="searchForm.perm_id"
                filterable
                placeholder="请选择审批模块"
                clearable
                @visible-change="getPermList($event)"
                @change="getPermId"
              >
                <el-option
                  v-for="item in permList"
                  :key="item.perm_id"
                  :label="item.perm_name"
                  :value="item.perm_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6">
            <el-form-item label="所属账套：" >
              <el-select
                v-model="searchForm.acct_id"
                filterable
                placeholder="请选择审批账套"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_name"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="16">
            <el-form-item label="创建时间：" >
              <el-date-picker
                size="small"
                v-model="timeData"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="getAcctsNow()"
                class="vg_ml_16"
                >查询</el-button
              >
              <el-button
                type="info"
                size="small"
                class="vd_export"
                @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col>
          <div class="vd_button_group vg_mtb_16">
        <el-button
          type="primary"
          size="small"
          @click="addRow()"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增</el-button
        >
      </div> 
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :model="apprForm"
            :data="tableData"
            class="vd_table vg_pointer"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column
              label="权限模块ID"
              :show-overflow-tooltip="true"
              prop="perm_id"
            />
            <el-table-column
              label="所属模块"
              :show-overflow-tooltip="true"
              prop="perm_name"
            />
            <el-table-column
              label="所属账套"
              v-if="flag"
              :show-overflow-tooltip="true"
              prop="acct_name"
            />
            <el-table-column
              label="创建时间"
              v-if="flag"
              :show-overflow-tooltip="true"
              :formatter="formatDate"
              prop="create_time"
            />
            <el-table-column
              prop="appr_shelved"
              label="审批启用"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.appr_shelved == 0" type="danger"
                  >关闭</el-tag
                >
                <el-tag v-if="scope.row.appr_shelved == 1" type="success"
                  >开启</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
      <el-dialog
        title="新增审批配置"
        :visible.sync="dialogFormVisible"
        :before-close="handelClose"
        width="500px"
      > 
        <el-form
          ref="dialogForm"
          :model="dialogForm"
          label-width="120px"
          size="mini"
          :show-message="false"
        >
          <el-row>
            <el-col :md="20">
              <el-form-item label="所属模块：">
                <el-select
                  v-model="dialogForm.perm_id"
                  filterable
                  placeholder="请选择审批模块"
                  clearable
                  @visible-change="getPermList($event)"
                  @change="getPermId"
                >
                  <el-option
                    v-for="item in permList"
                    :key="item.perm_id"
                    :label="item.perm_name"
                    :value="item.perm_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
           
          </el-row>
          <el-row class="vg_pt_8">
            <el-col :md="20">
              <el-form-item label="所属账套：">
                <el-select
                  v-model="dialogForm.acct_id"
                  filterable
                  placeholder="请选择审批账套"
                  clearable
                >
                  <el-option
                    v-for="item in acctList"
                    :key="item.acct_id"
                    :label="item.acct_name"
                    :value="item.acct_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            </el-row>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="handelClose" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit('dialogForm')" size="small"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { permAPI } from "@api/modules/perm";
import { apprAPI } from "@api/modules/appr"; 
import { acctAPI } from "@api/modules/acct";
import pubPagination from "@/components/common/pubPagination";
export default {
  name: "ApprList",
  components: {
    pubPagination,
  },
  data() {
    return {
      apprForm: {
        perm_name: "",
      },
      dialogForm: {
        perm_name: "",
        perm_id: null,
        acct_id: null,
        
      },
      dialogFormVisible: false,
      permList: [],
      tableData: [],
      btn: {},
      flag: false,
      isLoading: true,
      acctList: [],
      searchForm: {
        page_no: 1,
        acct_id: "",
        perm_id: "",
      },
      currentIndex: 1,
      totalPage: 0,
      timeData:[]
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.isLoading = "ture";
      this.getAcct();
      this.getApprs();
    },
    // 获取permId
    getPermList(flag) {
      if (flag === true && this.permList.length === 0) {
        get(permAPI.getPermsLevel1)
          .then((res) => {
            if (res.data.code === 0) {
              this.permList = res.data.data;
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.create_time);
    },
    // 选中值
    getPermId() {
      this.permList.find((item) => {
        if (item.perm_id === this.dialogForm.perm_id) {
          this.dialogForm.perm_name = item.perm_name;
        }
      });
    },
    // 获取审核列表
    getApprs() {
      // if (this.searchForm.create_time) {
      //   this.searchForm.start_time = this.helper.toTimeVal(
      //     this.searchForm.create_time[0]
      //   );
      //   this.searchForm.end_time = this.helper.toTimeVal(
      //     this.searchForm.create_time[1]
      //   );
      // } 
      let timeNewVal = this.helper.getTime(this.timeData)
      this.searchForm.start_time = timeNewVal.startTime
      this.searchForm.end_time = timeNewVal.endTime
      get(apprAPI.getAllApprs, this.searchForm)
        .then((res) => {
          if (res.data.code === 0) {
            this.totalPage = res.data.data.total;
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
            this.tableData = res.data.data.list;
            this.btn = res.data.data.btn;
            // console.log('12214',this.$cookies.get('userInfo'))
            if (this.$cookies.get("userInfo").acct_id === 1) {
              this.flag = true;
            }
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 新增
    addRow() {
      this.dialogFormVisible = true;
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump("/appr_edit", {
        perm_id: this.$route.query.perm_id,
        form_id: row.appr_id,
      });
    },
    //提交表单
    submit(formName) {
      if (!this.dialogForm.perm_id) {
        return this.$message.warning("请选择权限名称");
      }
      if (!this.dialogForm.acct_id) {
        return this.$message.warning("请选择审批账套");
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.saveInfo();
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    // 保存
    saveInfo() {
      if (!this.dialogForm.acct_id) {
        return this.$message.warning("请选择审批账套");
      }
      post(apprAPI.addAppr, this.dialogForm)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
            this.dialogFormVisible = false;
            this.dialogForm.perm_name = "";
            this.dialogForm.perm_id = ''
            this.dialogForm.acct_id = ''
            this.getApprs();
          } else {
            let msg = res.data.msg;
            this.$message({
              type: "error",
              message: msg,
            });
            this.dialogForm.perm_id = '' 
      this.dialogForm.acct_id = ''
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //查询
    getAcctsNow() {
      this.$refs.pubPagination.currentPage = 1
      this.searchForm.page_no = 1;
      this.initData();
    },
    //刷新
    buttonRefresh() {
      this.$refs.pubPagination.currentPage = 1
      this.searchForm.page_no = 1;
      this.searchForm.perm_id = "";
      this.searchForm.acct_id = "";
      this.timeData = [];
      this.initData();
    },
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.initData();
    },
    handelClose(){
      this.dialogForm.perm_id = '' 
      this.dialogForm.acct_id = ''
      this.dialogFormVisible = false 
    }
  },
};
</script>

<style scoped lang="scss">
// .vd_table {
//   width: 20%;
// }
</style>